import breakpoint from '../breakpoint.json';
import palette from '../palette.json';
const theme = {
  colors: {
    primary: {
      10: palette.primary[10],
      20: palette.primary[20],
      30: palette.primary[30],
      40: palette.primary[40],
      50: palette.primary[50],
      60: palette.primary[60]
    },
    orange: {
      10: palette.orange[10],
      20: palette.orange[20],
      30: palette.orange[30],
      40: palette.orange[40],
      50: palette.orange[50],
      60: palette.orange[60]
    },
    yellow: {
      10: palette.yellow[10],
      20: palette.yellow[20],
      30: palette.yellow[30],
      40: palette.yellow[40],
      50: palette.yellow[50],
      60: palette.yellow[60]
    },
    grey: {
      10: palette.grey[10],
      20: palette.grey[20],
      30: palette.grey[30],
      40: palette.grey[40],
      50: palette.grey[50],
      60: palette.grey[60]
    }
  },
  breakpoint: {
    xs: `${breakpoint.xs - 1}px`,
    sm: `${breakpoint.sm - 1}px`,
    md: `${breakpoint.md - 1}px`,
    lg: `${breakpoint.lg - 1}px`,
    xl: `${breakpoint.xl - 1}px`,
    xxl: `${breakpoint.xxl - 1}px`
  },
  layout: {
    width: '1140px'
  }
};
export default theme;