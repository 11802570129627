import { FC, useEffect } from 'react';
import { message } from 'antd';
import { messageStore } from '@modules/core/adapter/outbound/stores/ui.store';
const AlertMessage: FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const {
    messageInfo,
    clearMessageInfo
  } = messageStore(state => state);
  useEffect(() => {
    if (messageInfo.text) alertMessage();
  }, [messageInfo]);
  const alertMessage = () => {
    messageApi.open({
      type: messageInfo.status,
      content: messageInfo.text,
      duration: messageInfo.duration ?? 3
    });
    clearMessageInfo();
  };
  return contextHolder;
};
export default AlertMessage;