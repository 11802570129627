import { DefaultTheme } from 'styled-components';
import globalTheme from './globalTheme';
const {
  colors,
  layout,
  breakpoint
} = globalTheme;
const {
  grey,
  orange,
  primary,
  yellow
} = colors;
export const theme: DefaultTheme = {
  colors: {
    primary: {
      [10]: primary[10],
      [20]: primary[20],
      [30]: primary[30],
      [40]: primary[40],
      [50]: primary[50],
      [60]: primary[60]
    },
    orange: {
      [10]: orange[10],
      [20]: orange[20],
      [30]: orange[30],
      [40]: orange[40],
      [50]: orange[50],
      [60]: orange[60]
    },
    yellow: {
      [10]: yellow[10],
      [20]: yellow[20],
      [30]: yellow[30],
      [40]: yellow[40],
      [50]: yellow[50],
      [60]: yellow[60]
    },
    grey: {
      [10]: grey[10],
      [20]: grey[20],
      [30]: grey[30],
      [40]: grey[40],
      [50]: grey[50],
      [60]: grey[60]
    }
  },
  breakpoint: {
    xs: breakpoint.xs,
    sm: breakpoint.sm,
    md: breakpoint.md,
    lg: breakpoint.lg,
    xl: breakpoint.xl,
    xxl: breakpoint.xxl
  },
  layout: {
    width: layout.width
  }
};