import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import type { AppProps } from 'next/app';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { ThemeProvider } from 'styled-components';
import AlertMessage from '@providers/core/AlertMessage.provider';
import { token } from '@shared/assets/theme/antdTheme';
import { theme } from '@shared/assets/theme/styledComponentTheme';
import '@shared/styles/globals.css';
import GlobalStyle from '@shared/styles/GlobalStyle';
import { UtagProvider } from '@shared/utils/hooks/useUtag.utils';
export default function MyApp({
  Component,
  pageProps
}: AppProps) {
  useEffect(() => {
    clarity.init(process.env.MICROSOFT_CLARITY_KEY);
  }, []);
  return <UtagProvider>
            <ConfigProvider theme={{
      token
    }}>
                <ThemeProvider theme={theme}>
                    <GlobalStyle />
                    <AlertMessage />
                    <Component {...pageProps} />
                </ThemeProvider>
            </ConfigProvider>
        </UtagProvider>;
}